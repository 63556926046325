.cta {
  display: flex;
  align-items: center;
  color: $black;

  cursor: pointer;

  transition: opacity 0.3s ease-in-out;

  &--primary {
    text-transform: uppercase;
    letter-spacing: pxToRem(10);
    font-weight: bold;
  }

  &__icon {
    margin-left: 2rem;
    margin-bottom: 0.3rem;
  }

  &:hover {
    opacity: 0.35;
  }
}
