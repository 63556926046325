@mixin ipad {
  @media (min-width: 765px) {
    @content;
  }
}

@mixin ipad-pro {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin desktop-xl {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin desktop-xxl {
  @media (min-width: 1919px) {
    @content;
  }
}
