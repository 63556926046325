.content {
  display: flex;
  flex-flow: column nowrap;

  font-size: clamp(12px, 3vw, 16px);
  padding: 3rem 1.8rem;

  &__title {
    font-size: pxToRem(26);
    margin-bottom: 0.35rem;

    &--secondary {
      text-transform: uppercase;
      font-size: pxToRem(16);
      letter-spacing: 0.2em;
    }
  }

  &__text {
    line-height: 1.7;
    color: $gray;
    font-weight: 500;
  }

  &__cta {
    margin-top: 3rem;
    display: flex;
  }

  @include ipad {
    padding: 4.5rem 3.5rem;

    &__title {
      font-size: pxToRem(32);
    }
  }

  @include ipad-pro {
    padding: 8rem;
  }

  @include desktop {
    flex: 2;
    background: white;
    padding: 0 4rem 3rem 4rem;

    &__text {
      font-size: pxToRem(12);
    }

    &__text--secondary {
      font-size: pxToRem(12);
    }

    &__title--secondary {
      font-size: pxToRem(12);
      margin-bottom: 0.25rem;
      align-self: flex-start;
    }
  }

  @include desktop-large {
    &__title {
      font-size: pxToRem(38);
      margin-bottom: 2.5rem;
      &--secondary {
        font-size: pxToRem(12);
        margin-bottom: 0.25rem;
        align-self: flex-start;
      }
    }

    &__text {
      font-size: pxToRem(12);

      &--secondary {
        font-size: pxToRem(12);
      }
    }

    padding: 0 6rem 3rem 6rem;
  }
}
