.hamburger {
  position: absolute;
  top: 35px;
  left: 1.8rem;
  height: fit-content;

  z-index: 4;

  margin-top: 3px;

  -webkit-user-select: none;
  user-select: none;

  cursor: pointer;

  &__line {
    display: block;

    width: 28px;
    height: 3px;
    margin-bottom: 6px;

    background: $white;
    border-radius: 1px;

    z-index: 1;

    transition: transform 0.3s ease-in-out;

    &--transform {
      background: lighten($gray, 15%);
      margin-top: 4px;
      transform: rotate(180deg);

      &:nth-child(1) {
        width: 24px;
        transform: rotate(45deg) translate(3px, 3px);

        @include ipad-pro {
          width: 28px;
        }
      }

      &:nth-child(2) {
        opacity: 0;
        visibility: hidden;
      }

      &:nth-child(3) {
        width: 24px;
        transform: rotate(-45deg) translate(10px, -10px);

        @include ipad-pro {
          width: 28px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include desktop {
    display: none;
  }
}
