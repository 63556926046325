.menu {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  margin: 0;
  align-items: center;
  list-style: none;
  padding-bottom: 0.3rem;

  &__item {
    margin-right: 1.65rem;
  }

  &__link {
    position: relative;
    font-weight: 400;
    text-decoration: none;
    color: $black;
    font-size: pxToRem(12);

    @include ipad {
      font-size: pxToRem(14);
    }

    @include ipad-pro {
      font-size: pxToRem(16);
      margin-right: 2rem;
    }

    @include desktop {
      color: $white;
      width: 100%;
    }
  }

  /*  TODO: animated border */
  &--active {
  }

  @include desktop {
    justify-content: flex-start;
    margin-left: 2rem;
    margin-top: 0.2rem;

    &__link:hover:after {
      content: "";
      position: absolute;
      display: block;
      width: 20px;
      height: 3px;
      margin-top: 0.6rem;
      background-color: $white;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
