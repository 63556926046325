.hero {
  min-height: fit-content;

  &__left {
    height: 55vh;
    // background-image: url("../images/mobile-image-hero-1.jpg");
    background-size: cover;
    @include ipad {
      background-position: 20% 80%;
    }

    // @include ipad-pro {
    //   background-image: url("../images/desktop-image-hero-1.jpg");
    // }

    @include desktop {
      position: relative;
      flex: 3;
    }
  }

  &__right {
    @include desktop {
      flex: none;
      width: calc(30% + 188px);
      // flex: none;
      // width: 600px;
    }
  }

  @include desktop {
    display: flex;
    height: 65vh;

    &__content {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }

    &__left {
      height: 100%;
    }
  }
}
