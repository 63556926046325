@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@500;600;700&display=swap");

@import "./normalize";
@import "./variables";
@import "./functions";
@import "./utils";

@import "./components/components-dir";

* {
  box-sizing: border-box;
}

body {
  font-family: "Spartan", sans-serif;
  height: 100vh;
}

#App {
  height: 100%;
}

.image-sm-1 {
  background-image: url("../images/mobile-image-hero-1.jpg");
}

.image-sm-2 {
  background-image: url("../images/mobile-image-hero-2.jpg");
}

.image-sm-3 {
  background-image: url("../images/mobile-image-hero-3.jpg");
}

.image-1 {
  background-image: url("../images/desktop-image-hero-1.jpg");
}

.image-2 {
  background-image: url("../images/desktop-image-hero-2.jpg");
}

.image-3 {
  background-image: url("../images/desktop-image-hero-3.jpg");
}
