.header {
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 0 1.8rem;

  &__logo {
    order: 2;
    height: 16px;

    @include ipad-pro {
      height: 20px;
    }
  }

  @include desktop {
    justify-content: flex-start;
  }
}
